body {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas: "main" "footer";
  overflow-x: hidden;
  background: #f5f7fa;
  min-height: 100vh;
  font-family: "Open Sans", sans-serif;
}

body .footer {
  z-index: 1;
  --footer-background: white;
  display: grid;
  position: relative;
  grid-area: footer;
  min-height: 12rem;
}

body .footer .bubbles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1rem;
  background: var(--footer-background);
  filter: url("#blob");
}

body .footer .bubbles .bubble {
  position: absolute;
  left: var(--position, 50%);
  background: var(--footer-background);
  border-radius: 100%;
  -webkit-animation: bubble-size var(--time, 4s) ease-in infinite
      var(--delay, 0s),
    bubble-move var(--time, 4s) ease-in infinite var(--delay, 0s);
  animation: bubble-size var(--time, 4s) ease-in infinite var(--delay, 0s),
    bubble-move var(--time, 4s) ease-in infinite var(--delay, 0s);
  transform: translate(-50%, 100%);
}

body .footer .content {
  z-index: 2;

  padding: 0.5rem;
  background: var(--footer-background);
}

body .footer .content a,
body .footer .content p {
  text-decoration: none;
}

body .footer .content b {
  color: white;
}

body .footer .content p {
  margin: 0;
  font-size: 0.75rem;
}

body .footer .content > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

body .footer .content > div > div {
  margin: 0.25rem 0;
}

body .footer .content > div > div > * {
  margin-right: 0.5rem;
}

body .footer .content > div .image {
  align-self: center;
  width: 4rem;
  height: 4rem;
  margin: 0.25rem 0;
  background-size: cover;
  background-position: center;
}

@-webkit-keyframes bubble-size {
  0%,
  75% {
    width: var(--size, 4rem);
    height: var(--size, 4rem);
  }

  100% {
    width: 0rem;
    height: 0rem;
  }
}

@keyframes bubble-size {
  0%,
  75% {
    width: var(--size, 4rem);
    height: var(--size, 4rem);
  }

  100% {
    width: 0rem;
    height: 0rem;
  }
}

@-webkit-keyframes bubble-move {
  0% {
    bottom: -4rem;
  }

  100% {
    bottom: var(--distance, 10rem);
  }
}

@keyframes bubble-move {
  0% {
    bottom: -4rem;
  }

  100% {
    bottom: var(--distance, 10rem);
  }
}
